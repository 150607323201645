import { inject, Injectable } from '@angular/core';
import { marked } from 'marked';
import OpenAI from 'openai';
import { AssistantStream } from 'openai/lib/AssistantStream.mjs';
import { ConfigService } from './config.service';

export const localStorageThreadIdKey = 'thread-id';
export const localStorageOrganizationKey = 'organization';

const fixLinkWithSpace = (markDown: string) => {
  return markDown.replace(/(\[[^\]]*\])\(([^)]*)\)/g, '$1(<$2>)');
}

const fixMarkdown = (markDown: string) => {
  return fixLinkWithSpace(markDown);
}

const markdownToHtml = (markDown: string) => {
  return  marked.parse(markDown.replace(/^[\u200B\u200C\u200D\u200E\u200F\uFEFF]/,"")) as string;
}

// So we can add horizontal scroll on table
const wrapTableAroundTableContainer = (html: string) => {
  const tableIndex = html.indexOf('<table');
  if (tableIndex > -1) {
    const tableEndIndex = html.indexOf('</table>');
    const table = html.slice(tableIndex, tableEndIndex + 8);
    return html.replace(table, `<div class="table-container">${table}</div>`);
  }
  return html;
}

const fixHtml = (html: string) => {
  return wrapTableAroundTableContainer(html).trim();
}

export const openAIMessageContentToHTML = (content: OpenAI.Beta.Threads.Messages.MessageContent[]) => {
  const markdown = content.reduce((acc, c) => {
    if (c.type === 'text') {
      let newValue = c.text.value;
      c.text.annotations.forEach(annotation => {
        if (annotation.type === 'file_citation' || annotation.type === 'file_path') {
          newValue = newValue.replaceAll(annotation.text, '');
        }
      })
      return `${acc} ${newValue}`;
    }

    return acc;
  }, '');

  // Remove all unhandled annotations
  const markDownWithRemovedAnnotation = markdown.replace(/【[^】]+】/g, '');

  return fixHtml(markdownToHtml(fixMarkdown(markDownWithRemovedAnnotation)));
}

export const extractFollowupQuestions = (html: string) => {
  const identifier = '¿';
  if (html.endsWith('</p>')) {
    const index = html.lastIndexOf('<p>');
    const list = html.substring(index,);
    if (list.includes(identifier)) {
      const content = html.substring(0, index);
      return {
        content,
        followUpMessages: list
          .replace('<p>','')
          .replace('</p>', '')
          .split(identifier)
          .map(i => i.trim())
          .filter(i => !!i)
          .map(content => {
            return {
              content
            }
          }),
      }
    }
    return {
      content: html,
      followUpMessages: [],
    }
  }
  return {
    content: html,
    followUpMessages: [],
  }
}

export interface Message {
  id: string;
  loading?: boolean;
  content: string;
  role: 'user' | 'assistant';
  followUpMessages: SuggestedMessage[]
}

export interface SuggestedMessage {
  content: string;
}

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private readonly configService = inject(ConfigService);

  public initializeThread() : Promise<void> {
    return Promise.resolve();
  }

  public async startNewThread(organizationKey: string): Promise<string> {
    return this.configService.getConfig(organizationKey).then(config => {
      const openai = new OpenAI({
        apiKey: config.apiKey,
        dangerouslyAllowBrowser: true,
      });
      return openai.beta.threads.create()
        .then(thread => {
          return thread.id;
        });
    });
  }

  public threadExists(organizationKey: string, threadId: string) : Promise<boolean> {
    return new Promise(res => {
      this.configService.getConfig(organizationKey).then(config => {
        const openai = new OpenAI({
          apiKey: config.apiKey,
          dangerouslyAllowBrowser: true,
        });
        openai.beta.threads.retrieve(threadId)
          .then(exists => {
            res(!!exists)
          })
          .catch(() => {
            res(false);
          })
      })
    });
  }


  public createNewMessage(organizationKey: string, threadId: string, message: string) : Promise<[string, AssistantStream]> {
    return this.configService.getConfig(organizationKey).then(config => {
      const openai = new OpenAI({
        apiKey: config.apiKey,
        dangerouslyAllowBrowser: true,
      });
      return openai.beta.threads.messages.create(
        threadId,
        {
          content: message,
          role: 'user'
        }
      )
        .then((createdMessage) => {
          return [createdMessage.id, openai.beta.threads.runs.stream(
            threadId,
            {
              assistant_id: config.assistantId
            }
          )];
        });
    });
  }

  public getAllMessages(organizationKey: string, threadId: string): Promise<Message[]> {
    return this.configService.getConfig(organizationKey).then(config => {
      const openai = new OpenAI({
        apiKey: config.apiKey,
        dangerouslyAllowBrowser: true,
      });
      return openai.beta.threads.messages.list(
        threadId
      ).then(messages => {
        const m : {
          id: string;
          role: 'user' | 'assistant';
          content: string;
          followUpMessages: SuggestedMessage[]
        }[] = [];
        if('data' in messages) {
          messages.data.forEach(item => {
            const { content, followUpMessages } = extractFollowupQuestions(openAIMessageContentToHTML(
              item.content
            ));
            m.push({
              id: item.id,
              content,
              role: item.role,
              followUpMessages,
            })
          });
        }
        return m.reverse();
      })
    })
  }

  public askChatGPT(organizationKey: string, prompt: string): Promise<string> {
    return this.configService.getConfig(organizationKey).then(config => {
      const openai = new OpenAI({
        apiKey: config.apiKey,
        dangerouslyAllowBrowser: true,
      });
      return openai.chat.completions.create({
        model: 'gpt-4',
        messages: [{"role": "user", "content": prompt}]
      }).then(a => {
        return a.choices.map(c => c.message.content).join('');
      });
    })
  }
}
